import React from 'react';
import { Helmet } from 'react-helmet';

import Layout from '../components/layout';

export default function HomePage() {
  return (
    <>
      <Helmet>
        <title>Two Thumbs Up</title>
      </Helmet>
      <Layout isHome={true}>
        <article className="prose">
          <h1>Welcome to Two Thumbs Up, your home renovation destination.</h1>
        </article>
      </Layout>
    </>
  );
}
